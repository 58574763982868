:root {
  --purple: #5d2973;
  --purple--light: #a61f51;
  --orange: #f24e29;
  --orange--light: #f29a2e;
  --blue: #33a1d3;
}

@font-face {
  font-family: "Work Sans";
  src: url("./home-js/fonts/work-sans-v18-latin-800.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Work Sans";
  overflow: hidden;
}

html,
body,
#root,
.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    min-height: 100vh;

    @supports (height: 100dvh) {
      min-height: 100dvh;
    }
  }
}

.app > div:not([class]) {
  position: absolute !important;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  @media (max-width: 1280px) {
    padding: 0 50px;
  }

  @media (max-width: 960px) {
    padding: 0 25px;
  }

  &--wide {
    padding: 0 40px;

    @media (max-width: 960px) {
      padding: 0 25px;
    }
  }

  &--full {
    max-width: 1500px;
    padding: 0;
    overflow: hidden;
  }

  &--hidden {
    display: none;
  }
}

canvas {
  touch-action: none;

  @media (max-width: 960px) {
  }
}

.cursor {
  z-index: 9999999999;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  transition: opacity 0.4 ease-in-out;

  @media (hover: none) {
    //display: none;
  }

  &--active {
    opacity: 1;

    .cursor__inner {
      transform: scale(0.75);

      @media (hover: none) {
        display: none;
      }
    }

    .cursor__outer {
      animation: pulse-ring 1.1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;

      @media (hover: none) {
        display: none;
      }
    }
  }

  &__inner {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.4s ease-in-out;
    box-shadow: 0px 0px 15px 0.5px rgb(93 41 115 / 50%);

    @media (orientation: portrait), (hover: none), (max-width: 960px) {
      visibility: hidden;
    }
  }

  &__outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
    transform: scale(0.75);

    @media (orientation: portrait), (hover: none), (max-width: 960px) {
      visibility: hidden;
    }
  }

  &__label {
    background-color: #000;
    font-size: 18px;
    color: #fff;
    width: max-content;
    padding: 5px 10px;
    position: absolute;
    top: 50%;
    translate: 45px -50%;
    transition: opacity 0.5s ease-out, translate 0.3s ease-out;
    opacity: 0;

    @media (orientation: portrait), (hover: none), (max-width: 960px) {
      font-size: 16px;
      left: 50%;
      translate: -50% 50%;
      max-width: 150px;
      padding: 5px;
      text-align: center;
    }

    &--visible {
      opacity: 1;
    }

    &--reversed {
      translate: -90px -50%;
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.75);
  }

  90%,
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.model-link {
  z-index: 9 !important;
  pointer-events: auto !important;

  &__link {
    pointer-events: none !important;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    position: relative;

    &--hovered {
      opacity: 0.8;
    }
  }

  &__image {
    &--compass {
      filter: brightness(0.65);
    }
  }

  &__shape {
    display: block;
    pointer-events: auto !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;

    &--disabled {
      pointer-events: none !important;
    }

    &--square {
      rotate: 45deg;
      scale: 0.7;
    }

    &--triangle {
      clip-path: polygon(54% 0%, 0% 100%, 110% 100%);
    }

    &--triangle-alt {
      clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
    }
  }
}

.header {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  top: 0;
  left: 0;

  &__content {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 50px 0;

    @media (max-height: 700px) {
      padding: 30px 0;
    }

    @media (max-width: 960px) {
      justify-content: space-between;
      padding: 20px 0;
    }
  }

  &__logo-wrapper {
    opacity: 0;
  }

  &__logo {
    max-width: 276px;
    height: auto;

    @media (max-width: 960px) {
      max-width: min(180px, 40vw);
    }

    &--hidden {
      display: none;
    }
  }

  &__icons {
    position: absolute;
    right: 0;

    @media (max-width: 960px) {
      position: static;
    }
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.hamburger {
  display: block;
  height: 35px;
  width: 50px;
  padding: 5px;
  margin: 10px 0 0 0;
  z-index: 6;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;

  @media (max-width: 960px) {
    margin: 0;
  }
}

.hamburger__line {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  height: 5px;
  width: 100%;
  background-color: #fff;
  transition: translate 0.4s ease-in-out, rotate 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
  box-shadow: 0px 0px 20px #001d3c4d;
}

.hamburger__line--line1 {
  transform-origin: center;
}

.hamburger__line--line2 {
  translate: 0 15px;
}

.hamburger__line--line3 {
  translate: 0 30px;
  transform-origin: center;
}

.nav-opened .hamburger__line--line1 {
  translate: 0 11px;
  rotate: (45deg);
}

.nav-opened .hamburger__line--line2 {
  opacity: 0;
}

.nav-opened .hamburger__line--line3 {
  translate: 0 11px;
  rotate: (-45deg);
}

.nav__bg-overlay {
  position: fixed;
  top: -50px;
  right: -100px;
  height: 100vh;
  width: 0;
  z-index: -1;
  background-image: linear-gradient(rgb(0 0 0 / 70%), rgb(0 0 0 / 70%)),
    url(./media/background-overlay.jpeg);
  background-size: cover;
  opacity: 0;

  @media (max-width: 1280px) {
    right: -50px;
  }

  @media (max-height: 700px) {
    top: -30px;
  }

  @media (max-width: 960px) {
    top: -20px;
    right: -25px;
  }
}

.nav__menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: -50px;
  z-index: 5;
  background: transparent;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overscroll-behavior: none;
  width: 450px;
  transform: translate(150%);
  padding: 0;
  transition: transform 0.5s ease-in-out;

  @supports (height: 100dvh) {
    min-height: 100dvh;
  }

  @media (max-height: 700px) {
    top: -30px;
  }

  @media (max-width: 960px) {
    top: -20px;
  }

  @media (max-width: 600px) {
    width: 100vw;
  }
}

.nav-opened .nav__menu {
  transform: translateX(100px);

  @media (max-width: 1280px) {
    transform: translateX(50px);
  }

  @media (max-width: 600px) {
    transform: translateX(25px);
  }
}

.menu-section {
  padding: 22px 40px;

  a {
    transition: opacity 0.2s ease;
  }

  a:hover {
    opacity: 0.7;
  }

  &--events {
    padding-top: 105px;
    background: transparent
      linear-gradient(111deg, #531465 0%, #482b81 51%, #066459 100%) 0% 0%
      no-repeat padding-box;
  }

  &--industry {
    background: transparent
      linear-gradient(71deg, #340065 0%, #59003c 37%, #7b0036 62%, #df0041 100%)
      0% 0% no-repeat padding-box;
  }

  &--nav {
    background: transparent linear-gradient(170deg, #e6491a 0%, #92032f 100%) 0%
      0% no-repeat padding-box;
    height: -webkit-fill-available;
  }

  &--social-media {
    background: transparent linear-gradient(178deg, #0f3963 0%, #63c5b4 100%) 0%
      0% no-repeat padding-box;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 27px;
  }

  &__logo {
    max-width: 117px;
    width: 100%;

    @media (max-width: 600px) {
      max-width: 105px;
    }

    &--compass {
      transform: scale(1.2);
    }
  }

  &__title {
    font-size: 27px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin: 0;

    @media (max-width: 600px) {
      font-size: 25px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  gap: 9px;
  list-style: none;
  margin: 0;
  padding: 0;

  &--submenu {
    padding-left: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  &__link {
    font-size: 25px;
    line-height: 1.2;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0;
  }
}

.social-media {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: circle(100%);

  &__logo {
    max-width: 200px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;

    &--link {
      cursor: pointer;

      span {
        span {
          transition: background-color 0.8s ease;
        }
      }

      &:hover > span {
        opacity: 0.6;
      }
    }
  }

  &__text {
    font-size: 26px;
    text-transform: uppercase;
    color: #fff;

    span {
      display: inline-block;
    }
  }
}

.intro {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 99999999;

  &__container {
    width: inherit;
    height: inherit;
  }

  /* Player ratio: 100 / (1280 / 720) */
  &__video {
    overflow: hidden;
    max-width: 100%;

    & > div {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      width: 400% !important; /* enlarge beyond browser width */
      left: -150%; /* center */
    }
  }

  video {
    width: 100vw !important;
    height: auto !important;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.toggle-model {
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 10px 0;
  transition: opacity 1s 2.5s ease;
  pointer-events: auto;
  opacity: 0.9;

  &--hidden {
    pointer-events: none;
    opacity: 0;
  }

  &__label {
    color: #9c9c9c;
    font-size: 22px;
    line-height: 1.2;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    transition: opacity 0.3s 0.3s ease;
    svg {
      width: 20px;
      &:first-child {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        scale: 0.95;
        opacity: 1;
        animation: 1.3s ease-in-out 0s infinite icon-pulse-calm;
        will-change: opacity;
        will-change: scale;
      }
      path {
        fill: #9c9c9c;
      }
    }

    &--right {
      rotate: 180deg;
    }

    &--hidden {
      opacity: 0;
    }
  }
}

.footer {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  bottom: 0;
  left: 0;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 50px 0;

    @media (max-width: 960px) {
      position: relative;
      display: grid;
      padding: 35px 0 20px;
    }
  }

  &__buttons {
    display: grid;
    @media (max-width: 960px) {
      grid-row: 2;
      grid-column: 2/3;
    }
  }

  &__button {
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    &--sound {
      max-width: 50px;
      @media (max-width: 960px) {
        grid-row: 2;
        grid-column: 1 / 2;
      }
    }

    &--control {
      grid-area: 1 / 1;
      max-width: 48px;
      @media (max-width: 960px) {
        max-width: 38px;
      }
    }

    &--home {
      svg {
        pointer-events: none;
      }
    }

    &:hover {
      transform: scale(1.1);
    }

    svg {
      max-height: 50px;
      width: auto;
      filter: drop-shadow(0px 0px 20px #001d3c4d);

      @media (max-width: 960px) {
        display: grid;
        max-height: 40px;
      }
    }
  }
}

.explore-btn {
  position: absolute;
  color: #fff;
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: inherit;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 7px 20px 7px 30px;
  border: none;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease;

  @media (max-width: 960px) {
    grid-row: 1;
    grid-column: 1 / 3;
    min-width: 100%;
    padding: 11px 20px 11px 30px;
    margin-bottom: 35px;
    font-size: 22px;
    top: -95px;
  }

  &--hidden {
    display: none;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    svg:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      scale: 0.95;
      opacity: 1;
      animation: 1.3s ease-in-out 0s infinite icon-pulse;
      will-change: opacity;
      will-change: scale;
    }
  }

  &:hover {
    opacity: 0.7 !important;
  }
}

.dropdown {
  color: #fff;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  text-align: left;
  font-family: inherit;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 505px;
  border: none;
  position: relative;

  @media (max-width: 960px) {
    grid-row: 1;
    grid-column: 1 / 3;
    min-width: 100%;
    margin-bottom: 25px;
    font-size: 22px;
  }

  &--hidden {
    display: none;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    transition: width 0.3s ease;
  }

  &__arrow {
    position: absolute;
    right: 20px;
    height: 18px;
    transition: rotate 0.5s ease;

    &--active {
      rotate: 180deg;
    }
  }

  &__list-container {
    width: 100%;
    position: absolute;
    bottom: 53px;
    padding: 20px 20px 2px;
    background-color: #000;
    overflow-x: hidden;

    @media (max-width: 960px) {
      bottom: 44px;
    }

    &--hidden {
      display: none;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
  }

  &__list-item {
    width: 100%;
    background: linear-gradient(
      to right,
      var(--purple--light),
      var(--purple) 50%,
      #fff 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.5s ease-in-out;

    &:hover {
      background-position: 0 100%;
      filter: brightness(1.4);
    }
  }
}

.recommendations {
  width: 100%;
  visibility: hidden;

  @media (max-width: 960px) {
    margin: 20px 0 0 0;
  }

  &__title {
    font-size: clamp(28px, 3vw, 54px);
    text-align: center;
    margin: 0 0 55px 0;

    @media (max-height: 700px) {
      margin: 0 0 25px 0;
    }

    @media (max-width: 960px) {
      margin: 0 0 25px 0;
    }
  }

  &__button {
    color: #fff;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background: rgb(236, 24, 86);
    background: linear-gradient(
      110deg,
      rgba(236, 24, 86, 1) 40%,
      rgba(106, 20, 74, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 32px;
    margin: 0 auto;
    width: fit-content;
    transition: opacity 0.5s ease;
  }

  &__slider {
    padding: 0 0 50px 0;
    overflow: visible !important;

    @media (max-height: 700px) {
      padding: 0 0 35px 0;
    }
  }

  &__boxes {
    @media (max-width: 1365px) {
      //gap: 30px;
    }

    @media (max-width: 960px) {
      //gap: 20px;
    }
  }

  &__box-wrapper {
    padding: 0 20px;
    width: 220px;

    &:not(.react-multi-carousel-item--active) {
      opacity: 0.2;
      transition: opacity 0.3s ease;
    }

    &.react-multi-carousel-item--active
      + :not(.react-multi-carousel-item--active) {
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1), transparent 40%);
    }

    &:not(.react-multi-carousel-item--active)
      + :not(.react-multi-carousel-item--active) {
      mask-image: linear-gradient(270deg, rgba(0, 0, 0, 1), transparent 40%);
    }
  }
}

.box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 4px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  //flex: 0 1 220px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  aspect-ratio: 1;
  position: relative;

  &__title {
    color: #fff;
    font-size: clamp(18px, 2.7vw, 22px);
    text-transform: uppercase;
    z-index: 2;
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 85%;
    max-height: 85%;
    z-index: 1;
    pointer-events: none;
  }
}

.rotate-prompt {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  z-index: 99999999;
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-height: 549px) {
    display: flex;
  }

  h2 {
    font-size: 22px;
    color: #fff;
    text-align: center;
    max-width: 460px;
  }
}

@keyframes icon-pulse {
  0% {
    opacity: 1;
    transform: scale(0.95);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes icon-pulse-calm {
  0% {
    opacity: 1;
    transform-origin: 22px center;
    transform: scale(0.95);
  }

  100% {
    opacity: 0;
    transform: scale(1.8);
  }
}
